import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  animatedItem: {
    animation: '$blink 2s linear infinite',
  },
  '@keyframes blink': {
    '0%': {
      boxShadow: `0 0 5px ${primary.mild}`,
    },
    '50%': {
      backgroundColor: primary.light,
      boxShadow: `0 0 20px ${primary.dark}`,
    },
    '100%': {
      boxShadow: `0 0 5px ${primary.main}`,
    },
  },
  animatedItemSoft: {
    animation: '$blink-soft 1.5s linear infinite',
  },
  '@keyframes blink-soft': {
    '0%': {
      boxShadow: `0 0 3px ${primary.mild}`,
    },
    '50%': {
      backgroundColor: primary.mild,
      boxShadow: `0 0 12px ${primary.mild}`,
    },
    '100%': {
      boxShadow: `0 0 3px ${primary.mild}`,
    },
  },
}));

export default function Blinker({
  CustomComponent,
  children,
  innerRef,
  isBlinking,
  softIntensity,
  ...props
}) {
  const styles = useStyles();
  return (
    <CustomComponent
      ref={innerRef}
      {...props}
      className={clsx(
        softIntensity ? {
          [styles.animatedItemSoft]: isBlinking,
        } : {
          [styles.animatedItem]: isBlinking,
        },
      )}
    >
      {children}
    </CustomComponent>
  );
}

Blinker.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  innerRef: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  CustomComponent: PropTypes.object,
  isBlinking: PropTypes.bool.isRequired,
  softIntensity: PropTypes.bool,
};

Blinker.defaultProps = {
  innerRef: () => { },
  children: undefined,
  CustomComponent: IconButton,
  softIntensity: false,
};
