import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { statusToSpanish } from '../../helpers';
import DotsStatusTimeline from '../timeline/DotsStatusTimeline';

const ClickableInvoiceStatus = ({
  onClick,
  status,
  statusSteps,
}) => (
  <Grid
    direction="column"
    spacing={0.5}
    container
    onClick={onClick}
    sx={{
      cursor: 'pointer',
    }}
  >
    <Grid item>
      <Typography variant="body2" sx={{ color: 'primary.main' }}>
        {statusToSpanish()[status]}
      </Typography>
    </Grid>
    <Grid item>
      <DotsStatusTimeline statusSteps={statusSteps} />
    </Grid>
  </Grid>
);

ClickableInvoiceStatus.propTypes = {
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  statusSteps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      datetime: PropTypes.instanceOf(Date),
    }),
  ).isRequired,
};

export default ClickableInvoiceStatus;
