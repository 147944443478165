import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Tooltip } from '@mui/material';
import FingoLink from '../links/FingoLink';

const GoBackButton = () => {
  const history = useHistory();
  return (
    <Tooltip title="Volver">
      <span>
        <FingoLink
          to=".."
          color="primary"
          sx={{ mr: 4 }}
          onClick={() => history.goBack()}
        >
          <ArrowBackOutlinedIcon />
        </FingoLink>
      </span>
    </Tooltip>
  );
};

export default GoBackButton;
