import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useAvailableHeight } from '../../hooks';

const AvailableHeightBox = ({ children, ...restBoxProps }) => {
  const [height, refChange] = useAvailableHeight();
  return (
    <Box
      sx={{
        height,
      }}
      ref={refChange}
      {...restBoxProps}
    >
      {children}
    </Box>
  );
};

AvailableHeightBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AvailableHeightBox;
