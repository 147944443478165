import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const FullHeightBox = ({ children, ...restBoxProps }) => (
  <Box
    height="100%"
    {...restBoxProps}
  >
    {children}
  </Box>
);

FullHeightBox.propTypes = {
  children: PropTypes.node,
};
FullHeightBox.defaultProps = {
  children: null,
};

export default FullHeightBox;
